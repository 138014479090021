<template>
  <div class="footer-com" :style="{ position: positive }">
    <div class="logo">
      <img src="../assets/logo2.png" alt="">
      <div class="txt">AV. JOSE GALVEZ BARRENECHEA, 200, 102, URB. SANTA CATALINA, LIMA</div>
    </div>
    <div class="border"></div>
    <div class="info">
      <div class="item">
        <div class="imgbox">
          <img src="../assets/phone.png" alt="">
        </div>
        <div class="tit">Teléfono</div>
        <!-- +51 927574731 -->
        <div class="txt">+51 319877621</div>
      </div>
      <div class="item">
        <div class="imgbox">
          <img src="../assets/email.png" alt="">
        </div>
        <div class="tit">Correo</div>
        <!-- contacto@credipay.ltd -->
        <div class="txt">jasmine@credipay.ltd</div>
      </div>
      <div class="item">
        <div class="imgbox">
          <img src="../assets/whatsapp.png" alt="">
        </div>
        <div class="tit">WhatsApp</div>
        <div class="txt">+51 319877621</div>
      </div>
    </div>
    <div class="txt">
      <p>CREDIPAY S.A.C es una empresa registrada en la Unidad de Inteligencia Financiera de la Superintendencia de Banca y Seguros.</p>
      <p>El préstamo se aprobará si se cumplen los requisitos de las condiciones crediticias evaluadas previa solicitud a través de la plataforma web</p>
    </div>
  </div>
</template>

<script>
export default {
  props: ["positive"],
  data() {
    return {};
  },

  methods: {},
};
</script>

<style lang="scss" scoped>
.footer-com {
  // position: absolute;
  width: 100%;
  // bottom: 0;
  z-index: 999;
  // height: 251px;
  background: #580808;
  // display: flex;
  // justify-content: space-between;

  .logo {
    width: 60%;
    margin: 0 auto;
    padding: 40px 0px;
    text-align: center;
    border-bottom: 1px solid #fff;
    .txt {
      font-family: Arial-BoldMT, Arial;
      line-height: 30px;
      font-size: 20px;
      color: #fff;
      margin-top: 20px;
    }
    img {
      width: 300px;
      // height: 80px;
    }
  }
  // .border {
  //   width: 60%;
  //   margin: 0 auto;
  //   border-bottom: 1px solid #fff;
  // }
  .info {
    width: 80%;
    margin: 0 auto;
    padding: 60px 0px;
    display: flex;
    justify-content: space-between;

    .item {
      width: 30%;
      text-align: center;
      .imgbox {
        height: 50px;
        img {
          width: 40px;
        }
      }
      .tit {
        font-size: 20px;
        font-family: Arial-BoldMT, Arial;
        font-weight: normal;
        color: #FFFFFF;
        line-height: 28px;
      }
      .txt {
        font-size: 20px;
        font-family: ArialMT;
        color: #FFFFFF;
        line-height: 28px;
      }
    }
  }
  > .txt {
    width: 80;
    margin: 0 auto;
    padding: 60px 0px;
    font-size: 18px;
    line-height: 22px;
    font-family: ArialMT;
    color: #580808;
    background-color: #fff;
    text-align: center;
  }
  
}
</style>