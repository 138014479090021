<template>
  <div class="home-page">
    <myHead :stylehead="stylehead"></myHead>
    <!-- <section class="time-section">
      <img class="img" width="100%" src="../assets/homebg.png" />
      <div class="sub-title">
        <p>QPréstamo</p>
        <h1>Préstamos llegan a tiempo</h1>
        <div class="info">QPréstamo utiliza inteligencia artificial y tecnología de control de riesgos de big data para brindar a los usuarios servicios de crédito financiero convenientes, rápidos y seguros</div>
        <span class="home-line"></span>
        <div class="btnbox">
          <div class="btn">
            <img src="../assets/logo2.png" alt="">
            <span>Descargar ahora</span>
          </div>
          <div class="btns"></div>
        </div>
      </div>
    </section> -->
    <div class="body">
      <div class="topbox">
        <!-- <div class="top">
          <div class="left">
            <div class="txt">Obtener préstamos, hasta </div>
            <div class="money">
              <span>s/</span>3,000.00
            </div>
            <div class="info">¡Tres minutos para conseguir tu préstamo!</div>
          </div>
          <div class="right">
            <a class="google" href="https://play.google.com/store/apps/details?id=com.kerdiups.prestamos" target="_blank">
              <img src="../assets/googleplay.png" alt="">
              <div class="box">
                <div class="info">Descargar en</div>
                <div class="txt">Google Play</div>
              </div>
            </a>
          </div>
        </div> -->
        <div class="bgbox">
          <img src="../assets/homebg.png" alt="">
        </div>
      </div>
      <div class="one">
        <!-- <div class="title">
          <img src="../assets/title1.png" alt="">
        </div> -->
        <!-- <div class="box">
          <img src="../assets/boxbg.png" alt="">
          <div class="content">
            <div class="list">
              <div class="item" :class="{acitve: acitve === 1}" @click="acitve = 1">14 Días</div>
              <div class="item" :class="{acitve: acitve === 2}" @click="acitve = 2">31 Días</div>
              <div class="item" :class="{acitve: acitve === 3}" @click="acitve = 3">90 Días</div>
            </div>
            <div class="money">
              <span>$</span>{{money}}
            </div>
            <div class="slider">
              <el-slider v-model="money" :min="0" :max="3000"></el-slider>
            </div>
            <a class="btn" href="https://play.google.com/store/apps/details?id=com.kerdiups.prestamos" target="_blank">Préstamo ahora</a>
            <img class="icon icon2" src="../assets/icon2.png" alt="">
            <img class="icon icon3" src="../assets/icon3.png" alt="">
          </div>
        </div> -->
      </div>
      <div class="two">
        <div class="title">
          <img src="../assets/title2.png" alt="">
        </div>
        <div class="box">
          <div class="left">
            <div class="item">
              <div class="num">0.1</div>
              <div class="tit">Rápido</div>
              <div class="info">La revisión rápida solo toma 5 minutos, por lo que no tiene que esperar.</div>
            </div>
            <div class="item">
              <div class="num">0.2</div>
              <div class="tit">Seguro</div>
              <div class="info">La seguridad de los datos del usuario está protegida en tiempo real por la última tecnología, por lo que no tiene que preocuparse por ello.</div>
            </div>
            <div class="item">
              <div class="num">0.3</div>
              <div class="tit">Servicio</div>
              <div class="info">Al convertirse usted en nuestro usuario, siempre le brindaremos un servicio al cliente rápido y amable.</div>
            </div>
            <div class="item">
              <div class="num">0.4</div>
              <div class="tit">Confiable</div>
              <div class="info">Institución crediticia confiable, préstamo transparente, producto en el que confían millones de personas en todo el mundo.</div>
            </div>
          </div>
          <div class="right">
            <img src="../assets/icon1.png" alt="">
          </div>
        </div>
      </div>
      <div class="three">
        <div class="title">
          <img src="../assets/title3.png" alt="">
        </div>
        <div class="item">
          <div class="left">
            <img src="../assets/item1.png" alt="">
          </div>
          <div class="right">
            <div class="tit">Descargue</div>
            <div class="info">Descarga la aplicación a tu teléfono a través de Google Play</div>
          </div>
        </div>
        <div class="item">
          <div class="left">
            <img src="../assets/item2.png" alt="">
          </div>
          <div class="right">
            <div class="tit">Regístrese y rellene la solicitud</div>
            <div class="info">Puede registrarse directamente y solo le tomará unos 3 minutos completar su información personal para solicitar su primer préstamo.</div>
          </div>
        </div>
        <div class="item">
          <div class="left">
            <img src="../assets/item3.png" alt="">
          </div>
          <div class="right">
            <div class="tit">Préstamo aprobado</div>
            <div class="info">Felicidades, su préstamo ha sido aprobado y su primer préstamo ha sido depositado en su cuenta bancaria.</div>
          </div>
        </div>
      </div>
    </div>
    <myFoot />
    <goTop />
  </div>
</template>

<script>
import myHead from "@/components/header";
import myFoot from "@/components/footer";
import goTop from "@/components/goTop";

export default {
  data() {
    return {
      stylehead: {
        color: "#272727",
        background: "#fff",
      },
      positive: "absolute",
      money: 0,
      acitve: 1
    };
  },
  created() {
    console.log(this.language);
  },
  methods: {
    changeLanguage(lang) {
      this.language = lang;
    },
  },
  components: {
    myHead,
    myFoot,
    goTop,
  },
};
</script>

<style lang="scss" scoped>
.home-page {
  position: relative;
  // padding-bottom: 300px;

  .time-section {
    width: 100%;
    position: relative;
    .img {
      display: block;
      max-width: 100%;
      border: none;
      outline: none;
    }
    .sub-title {
      // width: 100%;
      position: absolute;
      top: 30%;
      left: 0%;
      font-size: 30px;
      line-height: 40px;
      color: #fff;
      padding-left: 80px;

      p {
        font-size: 80px;
        font-family: Baskerville-BoldItalic, Baskerville;
        font-weight: normal;
        color: #FFFFFF;
        line-height: 90px;
        letter-spacing: 1px;
      }
      h1 {
        font-size: 50px;
        font-family: Helvetica;
        color: #FFFFFF;
        line-height: 63px;
      }
      .info {
        width: 50%;
        font-size: 20px;
        font-family: ArialMT;
        color: #FFFFFF;
        line-height: 30px;
      }
      .btnbox {
        width: 250px;
        height: 50px;
        margin-top: 30px;
        position: relative;
        cursor: pointer;
        .btns {
          width: 100%;
          height: 100%;
          background: #FFCC9B;
          border-radius: 5px;
          position: absolute;
          left: 10px;
          top: 10px;
          // z-index: -1;
        }
        .btn {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #F4861D;
          border-radius: 5px;
          position: relative;
          z-index: 9;
          img {
            width: 30px;
            margin-right: 10px;
          }
          span {
            font-size: 20px;
            font-family: MuktaMahee-Bold, MuktaMahee;
            font-weight: bold;
            color: #FFFFFF;
            letter-spacing: 1px;
          }
        }
      }
    }
  }
  .body {
    width: 100%;
    background: #fff;

    .topbox {
      
      .top {
        padding: 40px 0px;
        display: flex;

        .left {
          width: 50%;
          text-align: center;
          
          .txt {
            font-size: 20px;
            font-family: ArialMT;
            color: #860000;
            line-height: 25px;
            letter-spacing: 1px;
          }
          .money {
            font-size: 50px;
            font-family: Maler-Regular, Maler;
            font-weight: bold;
            color: #860000;
            line-height: 70px;
            span {
              font-size: 24px;
            }
          }
          .info {
            font-size: 18px;
            font-family: ArialMT;
            color: rgba(39,39,39,0.5000);
            line-height: 25px
          }
        }
        .right {
          width: 50%;
          display: flex;
          align-items: center;
          justify-content: center;

          .google {
            width: 300px;
            display: flex;
            align-items: center;
            border-radius: 8px;
            border: 2px solid #860000;

            img {
              width: 70px;
            }
            .box {
              flex: 1;
              padding: 10px 0;
              text-align: center;
              .info {
                font-size: 18px;
                font-family: ArialMT;
                color: rgba(39,39,39,0.5000);
                line-height: 25px;
              }
              .txt {
                font-size: 30px;
                font-family: Maler-Regular, Maler;
                font-weight: bold;
                color: #860000;
                line-height: 40px;
              }
            }
          }
        }
      }
      .bgbox {
        width: 100%;
        img {
          width: 100%;
          vertical-align: middle;
        }
      }
    }
    .one,
    .two {
      .title {
        height: 100px;
        background: #860000;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .one {
      .box {
        width: 100%;
        background: #fff;
        position: relative;
        img {
          width: 100%;
        }
        .content {
          width: 100%;
          height: 100%;
          padding: 80px 0px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          position: relative;
          .list {
            width: 100%;
            display: flex;
            justify-content: space-around;
            
            .item {
              width: 150px;
              height: 55px;
              font-size: 20px;
              font-family: Maler-Regular, Maler;
              font-weight: 400;
              color: #860000;
              background: #FFFFFF;
              line-height: 55px;
              border-radius: 10px;
              border: 2px solid #860000;
              text-align: center;
              cursor: pointer;

              &.acitve {
                background: #860000;
                color: #FFFFFF;
              }
            }
          }
          .money {
            font-size: 50px;
            font-family: Maler-Regular, Maler;
            font-weight: 400;
            color: #860000;
            line-height: 70px;
            text-align: center;
            margin-top: 60px;
            span {
              font-size: 30px;
            }
          }
          .slider {
            width: 800px;
            margin: 0 auto;

            ::v-deep {
              .el-slider__bar {
                background: #860000;
              }
              .el-tooltip {
                width: 100%;
                height: 100%;
                // border-color: #860000;
                border: none;
                background: url('../assets/money.png');
                background-size: contain;
              }
            }
          }
          .btn {
            width: 400px;
            height: 60px;
            background: #860000;
            border-radius: 10px;
            margin-top: 60px;
            text-align: center;
            line-height: 60px;
            font-size: 20px;
            cursor: pointer;
            font-family: Maler-Regular, Maler;
            font-weight: 400;
            color: #FFFFFF;
          }
          .icon {
            position: absolute;
            width: 200px;
          }
          .icon2 {
            left: -15px;
            top: -15px;
          }
          .icon3 {
            right: -15px;
            bottom: -15px;
          }
        }
      }
    }
    .two {
      .box {
        display: flex;
        padding: 40px;
        .left {
          width: 70%;
          display: flex;
          flex-wrap: wrap;

          .item {
            width: 50%;
            padding: 40px;
            box-sizing: border-box;
            .num {
              font-size: 30px;
              font-family: Maler-Regular, Maler;
              font-weight: bold;
              color: #860000;
            }
            .tit {
              padding-left: 20px;
              font-size: 20px;
              font-family: ArialHebrew-Bold, ArialHebrew;
              font-weight: bold;
              color: #860000;
              line-height: 32px;
              letter-spacing: 2px;
            }
            .info {
              padding-left: 20px;
              font-size: 18px;
              font-family: ArialMT;
              color: #272727;
              line-height: 22px;
            }
          }
        }
  
        .right {
          width: 30%;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            width: 100%;
          }
        }
      }
    }
    .three {
      padding: 40px 80px;
      background: #EFEFEF;

      .title {
        height: 100px;
        line-height: 100px;
      }

      .item {
        display: flex;
        padding: 20px 0px;
        justify-content: space-between;
        .left {
          width: 35%;

          img {
            width: 100%;
          }
        }
        .right {
          width: 60%;
          padding-top: 20px;
          .tit {
            font-size: 20px;
            font-family: Helvetica-Bold, Helvetica;
            font-weight: bold;
            color: #860000;
            line-height: 24px;
            letter-spacing: 2px;
          }
          .info {
            font-size: 18px;
            font-family: ArialMT;
            color: #272727;
            line-height: 22px;
          }
        }
      }
    }
  }
}
</style>
