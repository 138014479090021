<template>
  <div class="home-page">
    <myHead :stylehead="stylehead"></myHead>
    <section class="time-section">
      <!-- <img class="img" width="100%" src="../assets/helpbg.png" /> -->
      <!-- <div class="sub-title">
        <p>QPréstamo</p>
        <div>preguntas frecuentes</div>
      </div> -->
      <!-- <div class="bottom-box">
        <img src="../assets/bottom.png" alt="">
      </div> -->
    </section>
    <div class="title">
      <!-- <img src="../assets/title5.png" alt=""> -->
      Política de Privacidad 
    </div>
    <div class="body">
      <div class="content">
        <div class="title1">Introducción</div>
        <div>En Credipay SAC, tomamos muy en serio la protección de la privacidad de nuestros usuarios. Nuestra política de privacidad (la "Política") está diseñada para proporcionarle información detallada sobre cómo gestionamos y protegemos los datos personales de los usuarios que utilizan nuestra aplicación y/o sitio web (la "Plataforma").</div>
        <div>Le instamos a leer cuidadosamente y comprender completamente nuestra Política antes de utilizar nuestros servicios. Al acceder a nuestra Plataforma o utilizar cualquiera de los servicios que ofrecemos (los "Servicios"), usted confirma su consentimiento y entendimiento de cómo manejamos su información personal. Valoramos su privacidad y nos comprometemos a mantener la confidencialidad y seguridad de sus datos. Si tiene alguna pregunta o inquietud relacionada con nuestra Política, no dude en ponerse en contacto con nosotros a través de los medios indicados al final de este documento. Agradecemos su confianza en Credipay SAC.</div>
        <div>&nbsp;</div>
        <div class="title1">1. Nuestra Responsabilidad en Relación a los Datos Personales</div>
        <div>En Credipay SAC, cumplimos estrictamente con la legislación vigente en relación con la captura, almacenamiento, divulgación y uso de sus datos personales. Esto incluye el acceso, administración, transmisión y eliminación de estos datos.</div>
        <div>&nbsp;</div>
        <div class="title1">2. Solicitudes de Autorización</div>
        <div class="indent"><li>SMS: Accedemos a los mensajes de texto (SMS) únicamente para revisar aquellos relacionados con asuntos financieros importantes. Estos mensajes nos proporcionan información crucial para evaluar sus obligaciones pendientes y los pagos realizados, lo que nos permite generar una puntuación crediticia precisa. Los datos se transfieren de forma segura y no se comparten con terceros.</li></div>
        <div class="indent"><li>Registros de Llamadas: Accedemos a los registros de llamadas (CALL LOG) para verificar la instalación de la aplicación en su dispositivo y enviar verificaciones de contraseñas dinámicas. Los registros de llamadas se monitorean exclusivamente para fines de verificación de contraseñas. Los datos se cargan y transmiten de manera segura y confidencial, sin compartir información con terceros.</li></div>
        <div class="indent"><li>Contactos: Recopilamos información de dos de sus contactos de emergencia para verificar su identidad, gestionar riesgos y prevenir fraudes. Usted puede hacerlo manualmente o seleccionar un contacto automáticamente. Los datos se transfieren de manera segura y no se comparten con terceros.</li></div>
        <div class="indent"><li>Cámara: Utilizamos la cámara de su dispositivo para verificar su identidad y prevenir el robo de información cuando solicita un préstamo.</li></div>
        <div class="indent"><li>Ubicación General: Recopilamos información de ubicación de manera aproximada para reducir riesgos. Operamos solo en Perú y los datos se almacenan de forma segura.</li></div>
        <div class="indent"><li>Almacenamiento de Datos: Sus datos identificables se encriptan y transmiten de forma segura a nuestro servidor.</li></div>
        <div class="indent"><li>Datos del Portapapeles: Recopilamos detalles de transacciones financieras del portapapeles de su dispositivo y los transmitimos de manera segura para evaluar riesgos crediticios.</li></div>
        <div class="indent"><li>Datos del Dispositivo: Recopilamos información específica de su dispositivo para identificación única, prevención de fraude y mejora de su perfil crediticio. Estos datos se transmiten de manera segura y no se comparten con terceros.</li></div>
        <div class="indent"><li>Calendario: Para ayudarle a configurar sus ajustes de pago, obtendremos sus permisos de calendario. Estos datos se transmiten de manera segura y no se comparten con terceros.</li></div>
        <div>&nbsp;</div>
        <div class="title1">3. Protección de la Información</div>
        <div>Aplicamos rigurosas medidas de seguridad físicas, administrativas y técnicas para proteger su información personal. Utilizamos la encriptación para mantener la privacidad de sus datos y asegurar una transmisión segura a nuestro servidor.</div>
        <div>&nbsp;</div>
        <div class="title1">4. Cookies</div>
        <div>Nuestra aplicación puede utilizar cookies para mejorar nuestros servicios. Usted tiene la opción de aceptar o rechazar estas cookies según su preferencia.</div>
        <div>&nbsp;</div>
        <div class="title1">5. Proveedores de Servicios</div>
        <div>Colaboramos con terceros para facilitar y mejorar nuestros servicios. Estos terceros tienen acceso a sus datos personales pero están comprometidos a salvaguardar su privacidad.</div>
        <div>&nbsp;</div>
        <div class="title1">6. Contacto con Nosotros</div>
        <div>Si tiene alguna pregunta, comentario o sugerencia relacionada con nuestra Política de Privacidad, le invitamos a ponerse en contacto con nosotros. Puede comunicarse con nosotros a través de los siguientes medios:</div>
        <div class="indent"><li>Correo Electrónico: contacto@credipay.ltd</li></div>
        <div class="indent"><li>Horario de Oficina: De lunes a sábado de 8:30 a 17:00</li></div>
        <div class="indent"><li>Página Web de la Empresa: https://www.credipay.ltd/#/home</li></div>
        <div>&nbsp;</div>
        <div>Esta Política de Privacidad entra en vigencia desde su aceptación y está sujeta a revisiones periódicas. Le recomendamos que la consulte regularmente para mantenerse informado sobre cómo preservamos y protegemos su privacidad. Estamos comprometidos a actualizar y mejorar continuamente nuestras prácticas de privacidad para asegurar la máxima transparencia y seguridad en el manejo de sus datos personales.</div>
      </div>
    </div>
    <myFoot />
    <goTop />
  </div>
</template>

<script>
import myHead from "@/components/header";
import myFoot from "@/components/footer";
import goTop from "@/components/goTop";
export default {
  data() {
    return {
      stylehead: {
        color: "#272727",
        background: "#fff",
      },
      positive: "absolute",
    };
  },
  methods: {},
  components: {
    myHead,
    myFoot,
    goTop,
  },
};
</script>

<style lang="scss" scoped>
.home-page {
  position: relative;
  .time-section {
    position: relative;
    .img {
      display: block;
      max-width: 100%;
      border: none;
      outline: none;
    }
    .sub-title {
      width: 100%;
      position: absolute;
      top: 30%;
      left: 0%;
      font-size: 30px;
      line-height: 40px;
      color: #fff;
      padding-left: 80px;

      p {
        font-size: 80px;
        font-family: Baskerville-BoldItalic, Baskerville;
        font-weight: normal;
        color: #FFFFFF;
        line-height: 90px;
        letter-spacing: 1px;
      }
      div {
        font-size: 50px;
        font-family: Helvetica;
        color: #FFFFFF;
        line-height: 63px;
      }
      .info {
        width: 50%;
        font-size: 20px;
        font-family: ArialMT;
        color: #FFFFFF;
        line-height: 30px;
      }
      .btnbox {
        width: 250px;
        height: 50px;
        margin-top: 30px;
        position: relative;
        cursor: pointer;
        .btns {
          width: 100%;
          height: 100%;
          background: #FFCC9B;
          border-radius: 5px;
          position: absolute;
          left: 10px;
          top: 10px;
          // z-index: -1;
        }
        .btn {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #F4861D;
          border-radius: 5px;
          position: relative;
          z-index: 9;
          img {
            width: 30px;
            margin-right: 10px;
          }
          span {
            font-size: 20px;
            font-family: MuktaMahee-Bold, MuktaMahee;
            font-weight: bold;
            color: #FFFFFF;
            letter-spacing: 1px;
          }
        }
      }
    }
    .bottom-box {
      position: absolute;
      left: 50%;
      bottom: 50px;
      transform: translateX(-50%);
      img {
        width: 88px;
        height: 44px;
      }
    }
    
  }
  > .title {
    height: 100px;
    background: #860000;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    color: #fff;
  }
  .body {
    width: 100%;
    background: #fff;
    padding: 50px 0px;
    
    .content {
      width: 80%;
      margin: 0 auto;

    }
  }
}
* {
  font-size: 16px;
  line-height: 2;
  color: #333;
  margin: 0;
  padding: 0;
  list-style: disc;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
  line-height: 0;
  clear: both;
}
.wrapper {
  padding: 0 16px;
}
@media screen and (min-width: 450px) {
  .wrapper {
    width: 1050px;
    margin: 0 auto;
  }
}

h1 {
  text-align: center;
}

.title {
  font-size: 20px;
  color: #333;
  font-weight: bold;
  text-align: center;
}

.title1 {
  font-weight: bold;
  font-size: 18px;
}

dd {
  margin-inline-start: 0;
}

.indent {
  text-indent: 2em;
}

p {
  line-height: 24px;
  margin-block-start: 0;
  margin-block-end: 0;
  font-weight: 300;
  font-family: PingFang SC, -apple-system, BlinkMacSystemFont, Helvetica Neue,
    Microsoft YaHei, Source Han Sans SC, Noto Sans CJK SC, WenQuanYi Micro Hei,
    sans-serif;
}
::v-deep .el-collapse-item__header {
    font-size: 18px !important;
  }

</style>
