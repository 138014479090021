<template>
  <div class="home-page">
    <myHead :stylehead="stylehead"></myHead>
    <section class="time-section">
      <img class="img" width="100%" src="../assets/helpbg.png" />
      <!-- <div class="sub-title">
        <p>QPréstamo</p>
        <div>preguntas frecuentes</div>
      </div> -->
      <!-- <div class="bottom-box">
        <img src="../assets/bottom.png" alt="">
      </div> -->
    </section>
    <div class="title">
      <img src="../assets/title5.png" alt="">
    </div>
    <div class="body">
      <div class="content">
        <div class="title1">¿Cuáles son los requisitos para solicitar un préstamo en la plataforma?</div>
        <div>Elegibilidad para solicitar un préstamo</div>
        <div class="indent">Residentes legales mayores de 18 años</div>
        <div class="indent">Cédula de identidad vigente</div>
        <div class="indent">Trabajo estable e ingresos mensuales fijos</div>
        <div class="indent">Tener una cuenta bancaria activa de acuerdo con el nombre en la tarjeta de identificación</div>
        <div class="title1">¿Por qué fue rechazada mi solicitud?",</div>
        <div class="indent">Se puede deber a varias razones basadas en los resultados de la evaluación de su solicitud de préstamo.</div>
        <div class="indent">Puede volver a presentar la solicitud en la fecha indicada en la aplicación.</div>
        <div class="title1">¿Cuáles son los pasos necesarios?</div>
        <div>* El préstamo solo requiere cuatro pasos</div>
        <div class="indent">1. Descargar la APLICACIÓN de Google Play Store</div>
        <div class="indent">2. Registrarse con su número de teléfono móvil</div>
        <div class="indent">3. Cargar la tarjeta de identidad y realizar el reconocimiento facial</div>
        <div class="indent">4. Enviar la solicitud de préstamo</div>
        <div class="title1">¿Cuánto tiempo lleva el proceso de solicitud?</div>
        <div class="indent">Analizaremos su aplicación en 10 minutos.</div>
        <div class="indent">Por favor, respóndenos cuando nos comuniquemos con usted para verificarlo.</div>
        <div class="title1">¿Cómo veo el estado de mi solicitud?</div>
        <div class="indent">Puede verificar el estado de la solicitud en la página del préstamo en la aplicación.</div>
        <div class="title1">¿Cuánto desembolso del préstamo recibiré?</div>
        <div class="indent">Puede ver el monto de préstamo que recibirá en la aplicación cuando solicite un préstamo.</div>
        <div class="title1">¿Puedo solicitar un nuevo préstamo antes de completar mi préstamo anterior?</div>
        <div class="indent">Puede solicitar un nuevo préstamo siempre que el límite del préstamo esté todavía disponible.</div>
      </div>
    </div>
    <myFoot />
    <goTop />
  </div>
</template>

<script>
import myHead from "@/components/header";
import myFoot from "@/components/footer";
import goTop from "@/components/goTop";
export default {
  data() {
    return {
      stylehead: {
        color: "#272727",
        background: "#fff",
      },
      positive: "absolute",
    };
  },
  methods: {},
  components: {
    myHead,
    myFoot,
    goTop,
  },
};
</script>

<style lang="scss" scoped>
.home-page {
  position: relative;
  // padding-bottom: 300px;
  .time-section {
    position: relative;
    .img {
      display: block;
      max-width: 100%;
      border: none;
      outline: none;
    }
    .sub-title {
      width: 100%;
      position: absolute;
      top: 30%;
      left: 0%;
      font-size: 30px;
      line-height: 40px;
      color: #fff;
      padding-left: 80px;

      p {
        font-size: 80px;
        font-family: Baskerville-BoldItalic, Baskerville;
        font-weight: normal;
        color: #FFFFFF;
        line-height: 90px;
        letter-spacing: 1px;
      }
      div {
        font-size: 50px;
        font-family: Helvetica;
        color: #FFFFFF;
        line-height: 63px;
      }
      .info {
        width: 50%;
        font-size: 20px;
        font-family: ArialMT;
        color: #FFFFFF;
        line-height: 30px;
      }
      .btnbox {
        width: 250px;
        height: 50px;
        margin-top: 30px;
        position: relative;
        cursor: pointer;
        .btns {
          width: 100%;
          height: 100%;
          background: #FFCC9B;
          border-radius: 5px;
          position: absolute;
          left: 10px;
          top: 10px;
          // z-index: -1;
        }
        .btn {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #F4861D;
          border-radius: 5px;
          position: relative;
          z-index: 9;
          img {
            width: 30px;
            margin-right: 10px;
          }
          span {
            font-size: 20px;
            font-family: MuktaMahee-Bold, MuktaMahee;
            font-weight: bold;
            color: #FFFFFF;
            letter-spacing: 1px;
          }
        }
      }
    }
    .bottom-box {
      position: absolute;
      left: 50%;
      bottom: 50px;
      transform: translateX(-50%);
      img {
        width: 88px;
        height: 44px;
      }
    }
    
  }
  > .title {
    height: 100px;
    background: #860000;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .body {
    width: 100%;
    background: #fff;
    padding: 50px 0px;
    
    .content {
      width: 80%;
      margin: 0 auto;

    }
  }
}
* {
  font-size: 16px;
  line-height: 2;
  color: #333;
  margin: 0;
  padding: 0;
  list-style: none;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
  line-height: 0;
  clear: both;
}
.wrapper {
  padding: 0 16px;
}
@media screen and (min-width: 450px) {
  .wrapper {
    width: 1050px;
    margin: 0 auto;
  }
}

h1 {
  text-align: center;
}

.title {
  font-size: 20px;
  color: #333;
  font-weight: bold;
  text-align: center;
}

.title1 {
  font-weight: bold;
  font-size: 18px;
}

dd {
  margin-inline-start: 0;
}

.indent {
  text-indent: 2em;
}

p {
  line-height: 24px;
  margin-block-start: 0;
  margin-block-end: 0;
  font-weight: 300;
  font-family: PingFang SC, -apple-system, BlinkMacSystemFont, Helvetica Neue,
    Microsoft YaHei, Source Han Sans SC, Noto Sans CJK SC, WenQuanYi Micro Hei,
    sans-serif;
}
::v-deep .el-collapse-item__header {
    font-size: 18px !important;
  }

</style>
