<template>
  <div class="header-com">
    <div class="wrap" :style="{color:stylehead.color,background:stylehead.background}">
      <div class="left-area">
        <img class="img" src="../assets/logo.png" width="220px;height:56px;" />
        <!-- PSPR Enterprises Private Limited -->
      </div>
      <div class="right-area">
        <ul class="list">
          <router-link v-for="(item,index) in currentData" :key='index' tag="li" class="item" :style="{color:stylehead.color}" :to="item.path">{{item.name}}</router-link>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props:['stylehead'],
  data() {
    return {
      currentData:[
        {name:'Inicio',path:'/home'},
        {name:'Producto',path:'/producto'},
        {name:'Ayuda',path:'/ayuda'},
        {name:'Sobre nosotros',path:'/about'},
        {name:'Política de Privacidad',path:'/policy'},
        {name:'Términos y Condiciones',path:'/condiciones'},
      ],
      selectFlag:false,
    };
  },
  mounted(){
  },
  methods: {
    showSelect() {
      this.selectFlag = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.header-com {
  // position: absolute;
  width: 100%;
  // top: 0;
  // z-index: 999;
  .wrap {
    display: flex;
    height: 80px;
    // line-height: 80px;
    align-items: center;
    .left-area {
      height: 100%;
      // padding-left: 110px;
      font-size: 21px;
      img{
        width: 100%;
        height: 100%;
        // vertical-align: middle;
      }
    }
   
    .right-area {
      flex: 1;
      text-align: right;
      .list {
        padding-right: 76px;
        // display: flex;
        .item {
          height: 25px;
          line-height: 25px;
          // padding: 8px 30px;
          display: inline-block;
          margin-right: 20px;
          color: #fff;
          text-align: left;
          font-size: 16px;
          border-radius: 5px;
          cursor: pointer;
          &.router-link-active {
            // border: 1px solid #fff;
            font-weight: bold;
            position: relative;
            &::after{
              width: 100%;
              height: 4px;
              background: #860000;
              position:absolute;
              display: block;
              content:" ";
              bottom:-10px;
              left: 50%;
              transform: translateX(-50%);
            }
          }
        }
        .selct-area {
          box-sizing: border-box;
          position: relative;
          display: inline-block;
          width: 104px;
          height: 32px;
          line-height: 32px;
          color: #fff;
          border-radius: 3px 3px;
          background: rgba(255, 255, 255, 0.3);
          font-size: 14px;
          text-align: left;
          padding-left: 6px;
          cursor: pointer;
          .text {
            display: inline-block;
            width: 100%;
            height: 100%;
            position: relative;
            padding-left: 6px;
            .icon {
              position: absolute;
              top: 12px;
              right: 16px;
              width: 0;
              height: 0;
              border-width: 6px;
              border-style: solid;
              border-color: #fff transparent transparent transparent;
            }
          }
          .select-list {
            box-sizing: border-box;
            width: 104px;
            position: absolute;
            left: 0;
            top: 32px;
            background: rgba(255, 255, 255, 0.3);
            padding-left: 6px;
            .select-item {
              box-sizing: border-box;
              font-size: 14px;
              padding-left: 6px;
            }
          }
        }
      }
    }
  }
  .top {
    display: flex;
    height: 80px;
    justify-content: space-between;
    align-items: center;

    .left-area {
      padding-left: 110px;
      img {
        height: 40px;
      }
    }
    .right-area {
      padding-right: 76px;
      font-size: 25px;
      font-family: WeibeiSC-Bold, WeibeiSC;
      font-weight: bold;
      color: #312C2C;
    }
  }
}
</style>