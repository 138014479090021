<template>
  <div class="home-page">
    <myHead :stylehead="stylehead"></myHead>
    <section class="time-section">
      <el-carousel class="carousel" :interval="5000" arrow="always">
        <el-carousel-item v-for="item in BannerList" :key="item.url">
          <img :src="item.url" />
          <!-- <div class="carousel-content">
            <p class="ban_tit">{{item.title}}</p>
            <p class="subtitle">{{item.subtitle}}</p>
          </div> -->
        </el-carousel-item>
      </el-carousel>
      <!-- <img class="img" width="100%" src="../assets/privacybg.png" /> -->
      <!-- <div class="sub-title">
        <p>QPréstamo</p>
        <div>Aviso de privacidad</div>
        <div class="btnbox">
          <div class="btn">
            <img src="../assets/logo2.png" alt="">
            <span>Descargar ahora</span>
          </div>
          <div class="btns"></div>
        </div>
      </div> -->
      <!-- <div class="bottom-box">
        <img src="../assets/bottom.png" alt="">
      </div> -->
    </section>
    <!-- <div class="title">
      <img src="../assets/title6.png" alt="">
    </div> -->
    <div class="body">
      <div class="content">
        <!-- <div class="title">Aviso De Privacidadde CREDIPAY</div> -->
        <div>&nbsp;</div>
        <div class="title1">Nuestro servicio</div>
        <div>Nuestro grupo ofrece múltiples productos de préstamos personales a corto plazo que van desde 91 días hasta 365 días. En el campo financiero, estamos comprometidos a brindar soluciones de servicios financieros de primer nivel a los usuarios en todo el mundo. Con la ayuda de publicidad personalizada en Google y Meta (anteriormente Facebook), análisis de datos, política de control de riesgos y soluciones de pago integrales, hacemos que los préstamos personales sean instantáneos y estén disponibles para todos los ciudadanos legales.</div>
      </div>
    </div>
    <myFoot :positive="positive" />
    <goTop />
  </div>
</template>

<script>
import myHead from "@/components/header";
import myFoot from "@/components/footer";
import goTop from "@/components/goTop";
import ProductBanner1 from "../assets/product/product_banner1.png";
import ProductBanner2 from "../assets/product/product_banner2.png";
export default {
  data() {
    return {
      language: 'le',
      BannerList: [
        { url: ProductBanner1, title: "Haga clic para una buena vida", subtitle: "Una solución rápida y sencilla para cualquier necesidad o urgencia. Una manera eficaz de convertir los fondos familiares" },
        { url: ProductBanner2, title: "Haga posible lo imposible, sin hacer nada", subtitle: "Estamos con usted en cualquier momento y en cualquier lugar. El préstamo sencilla hace la vida más fácil." },
      ],
      stylehead: {
        color: "#272727",
        background: "#fff",
      },
      positive: "absolute",
    };
  },
  mounted() {
    console.log('m')
    this.curUser = this.oneUsers;
  },
  methods: {
    
  },
  components: {
    myHead,
    myFoot,
    goTop,
  },
};
</script>

<style lang="scss" scoped>
.el-carousel {
  height: 680px;
  position:relative;

  .el-carousel__item {
    img {
      width: 100%;
      height: 100%;
      margin: 0;
    }
    .carousel-content {
      position: absolute;
      width: 70%;
      top: 180px;
      left: 200px;
      text-align: left;
      white-space: pre-wrap;
      .ban_tit {
        font-size: 48px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        line-height: 59px;
        letter-spacing: 2px;
      }
      .subtitle {
        margin-top: 15px;
        font-size: 25px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 35px;
        letter-spacing: 1px;
      }
    }
  }
}

::v-deep .el-carousel__container {
  height: 680px;
}
::v-deep .el-carousel__indicator--horizontal {
  .is-active button {
    width: 14px;
    height: 14px;
    background: #ffffff;
    border-radius: 50%;
  }
  button {
    width: 14px;
    height: 14px;
    border-radius: 50%;
  }
}
::v-deep .el-carousel__arrow {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: url(../assets/arrorleft.png) no-repeat;
  background-size: 100% 100%;
  cursor: pointer;
  .el-icon-arrow-left:before {
    content: "";
  }
}

::v-deep .el-carousel__arrow--right {
  transform-origin: center 20%;
  transform: rotate(180deg);
  // -webkit-transform: translateY(-20%);
  // transform: translateY(-20%);
  .el-icon-arrow-right:before {
    content: "";
  }
}
.home-page {
  position: relative;
  padding-bottom: 300px;

  // .time-section {
  //   position: relative;
  //   .img {
  //     display: block;
  //     max-width: 100%;
  //     border: none;
  //     outline: none;
  //   }
  //   .sub-title {
  //     width: 100%;
  //     position: absolute;
  //     top: 30%;
  //     left: 0%;
  //     font-size: 30px;
  //     line-height: 40px;
  //     color: #fff;
  //     padding-left: 80px;

  //     p {
  //       font-size: 80px;
  //       font-family: Baskerville-BoldItalic, Baskerville;
  //       font-weight: normal;
  //       color: #FFFFFF;
  //       line-height: 90px;
  //       letter-spacing: 1px;
  //     }
  //     div {
  //       font-size: 50px;
  //       font-family: Helvetica;
  //       color: #FFFFFF;
  //       line-height: 63px;
  //     }
  //     .info {
  //       width: 50%;
  //       font-size: 20px;
  //       font-family: ArialMT;
  //       color: #FFFFFF;
  //       line-height: 30px;
  //     }
  //     .btnbox {
  //       width: 250px;
  //       height: 50px;
  //       margin-top: 30px;
  //       position: relative;
  //       cursor: pointer;
  //       .btns {
  //         width: 100%;
  //         height: 100%;
  //         background: #FFCC9B;
  //         border-radius: 5px;
  //         position: absolute;
  //         left: 10px;
  //         top: 10px;
  //         // z-index: -1;
  //       }
  //       .btn {
  //         width: 100%;
  //         height: 100%;
  //         display: flex;
  //         justify-content: center;
  //         align-items: center;
  //         background: #F4861D;
  //         border-radius: 5px;
  //         position: relative;
  //         z-index: 9;
  //         img {
  //           width: 30px;
  //           margin-right: 10px;
  //         }
  //         span {
  //           font-size: 20px;
  //           font-family: MuktaMahee-Bold, MuktaMahee;
  //           font-weight: bold;
  //           color: #FFFFFF;
  //           letter-spacing: 1px;
  //         }
  //       }
  //     }
  //   }
  //   .bottom-box {
  //     position: absolute;
  //     left: 50%;
  //     bottom: 50px;
  //     transform: translateX(-50%);
  //     img {
  //       width: 88px;
  //       height: 44px;
  //     }
  //   }
  // }
  .title {
    height: 100px;
    background: #860000;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .body {
    width: 100%;
    background: #fff;
    padding: 50px 0px;
    
    .content {
      width: 80%;
      margin: 0 auto;

    }
  }
}
* {
  font-size: 16px;
  line-height: 2;
  color: #333;
  margin: 0;
  padding: 0;
  list-style: none;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
  line-height: 0;
  clear: both;
}
.wrapper {
  padding: 0 16px;
}
@media screen and (min-width: 450px) {
  .wrapper {
    width: 1050px;
    margin: 0 auto;
  }
}

h1 {
  text-align: center;
}

.title {
  font-size: 20px;
  color: #333;
  font-weight: bold;
  text-align: center;
}

.title1 {
  font-weight: bold;
  font-size: 18px;
}

dd {
  margin-inline-start: 0;
}

.indent {
  text-indent: 2em;
}

p {
  line-height: 24px;
  margin-block-start: 0;
  margin-block-end: 0;
  font-weight: 300;
  font-family: PingFang SC, -apple-system, BlinkMacSystemFont, Helvetica Neue,
    Microsoft YaHei, Source Han Sans SC, Noto Sans CJK SC, WenQuanYi Micro Hei,
    sans-serif;
}
::v-deep .el-collapse-item__header {
    font-size: 18px !important;
  }
</style>
