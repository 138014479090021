<template>
  <div class="home-page">
    <myHead :stylehead="stylehead"></myHead>
    <section class="time-section">
      <!-- <img class="img" width="100%" src="../assets/helpbg.png" /> -->
    </section>
    <div class="title">
      <!-- <img src="../assets/title5.png" alt=""> -->
      Términos y Condiciones
    </div>
    <div class="body">
      <div class="content">
        <div>Bienvenido al servicio de préstamos de Credipay SAC! Antes de utilizar este servicio, lea detenidamente los siguientes términos y condiciones para asegurarse de que los entiende y acepta. Si no está de acuerdo con alguno de los términos de este contrato, le pedimos que no utilice el servicio de Credipay SAC.</div>
        <div>&nbsp;</div>
        <div class="title1">1. Aceptación de los Términos</div>
        <div class="indent">1.1 Al descargar, instalar, registrarse, acceder o utilizar de cualquier otra forma el servicio de préstamos de Credipay SAC, usted está indicando que ha leído, comprendido y aceptado cumplir con los términos y condiciones de este contrato, así como con las políticas relacionadas. Si no está de acuerdo con alguno de los términos de este contrato, le rogamos que no utilice el servicio.</div>
        <div class="indent">1.2 El contenido de este contrato puede cambiar en cualquier momento. Las modificaciones entrarán en vigor tan pronto como se publiquen en el servicio de Credipay SAC y sustituirán automáticamente las disposiciones anteriores. Le sugerimos que consulte el contenido más reciente de este contrato en la aplicación o sitio web de Credipay SAC. El hecho de seguir utilizando el servicio de Credipay SAC se considerará como una aceptación de las versiones más recientes de los términos y condiciones.</div>
        <div>&nbsp;</div>
        <div class="title1">2. Contenido del Servicio</div>
        <div class="indent">2.1 El servicio de préstamos de Credipay SAC es una aplicación de software que brinda a los usuarios servicios de préstamos y evaluación crediticia, así como otros servicios financieros relacionados.</div>
        <div class="indent">2.2 Al utilizar el servicio de Credipay SAC, es posible que deba proporcionar información personal. Usted se compromete a brindar información veraz, precisa y completa. En caso contrario, podría ser responsable legalmente.</div>
        <div class="indent">2.3 Credipay SAC realizará evaluaciones crediticias basadas en la información proporcionada por los usuarios para determinar si son elegibles para recibir préstamos.</div>
        <div>&nbsp;</div>
        <div class="title1">3. Derechos y Obligaciones del Usuario</div>
        <div class="indent">3.1 Al utilizar el servicio de Credipay SAC, el usuario se compromete a cumplir con las leyes y regulaciones vigentes y a no utilizar el servicio para actividades ilegales o contrarias al orden público.</div>
        <div class="indent">3.2 El usuario debe mantener la confidencialidad de su información de cuenta y contraseña. El usuario será responsable de cualquier pérdida causada por la divulgación o pérdida de esta información.</div>
        <div class="indent">3.3 El usuario no podrá utilizar el servicio de Credipay SAC para infringir los derechos legales de terceros, incluidos entre otros los derechos de propiedad intelectual y la privacidad.</div>
        <div>&nbsp;</div>
        <div class="title1">4. Protección de la Privacidad</div>
        <div class="indent">4.1 Credipay SAC tomará medidas razonables para proteger la seguridad de la información personal del usuario. La información personal no se compartirá con terceros sin el consentimiento del usuario, excepto cuando lo exijan las leyes y regulaciones aplicables o cuando el usuario lo autorice expresamente.</div>
        <div class="indent">4.2 El usuario comprende y acepta que Credipay SAC puede divulgar su información personal basándose en requerimientos legales, decisiones judiciales, solicitudes de agencias gubernamentales u otras circunstancias legalmente permitidas.</div>
        <div>&nbsp;</div>
        <div class="title1">5. Cambios y Terminación del Servicio</div>
        <div class="indent">5.1 Credipay SAC se reserva el derecho de cambiar, suspender o terminar parcial o totalmente los contenidos del servicio en cualquier momento sin previo aviso.</div>
        <div class="indent">5.2 Si el usuario viola este contrato o participa en actividades ilegales o irregulares al utilizar el servicio de Credipay SAC, Credipay SAC se reserva el derecho de suspender o cancelar los servicios al usuario y de tomar las medidas legales correspondientes.</div>
        <div>&nbsp;</div>
        <div class="title1">6. Descargo de Responsabilidad</div>
        <div class="indent">6.1 El usuario comprende y acepta que utiliza el servicio de Credipay SAC bajo su propio riesgo.</div>
        <div class="indent">6.2 Credipay SAC no se responsabiliza por las pérdidas derivadas de fallas técnicas, interrupciones inesperadas, pérdida o daño de información, ataques de hackers, uso indebido del usuario, entre otros factores.</div>
        <div>&nbsp;</div>
        <div class="title1">7. Propiedad Intelectual</div>
        <div class="indent">7.1 Todo el contenido, información y tecnología incluidos en el servicio de Credipay SAC son propiedad exclusiva de Credipay SAC y están protegidos por las leyes de propiedad intelectual.</div>
        <div class="indent">7.2 El usuario no podrá copiar, difundir, mostrar, modificar, reproducir, vender ni utilizar de ninguna manera el contenido, la información o la tecnología de Credipay SAC sin el consentimiento previo y por escrito de Credipay SAC.</div>
        <div>&nbsp;</div>
        <div class="title1">8. Otras Disposiciones</div>
        <div class="indent">8.1 Este contrato se rige por las leyes y regulaciones de Perú.</div>
        <div class="indent">8.2 Si alguna disposición de este contrato es inválida o inaplicable, esto no afectará la validez de las demás disposiciones.</div>
        <div class="indent">8.3 La interpretación, ejecución y resolución de controversias relacionadas con este contrato se regirán por las leyes de Perú.</div>
        <div>&nbsp;</div>
        <div class="title1">9. Información de Contacto</div>
        <div>Si tiene alguna pregunta, no dude en ponerse en contacto con nosotros:</div>
        <div class="indent"><li>Correo Electrónico: contacto@credipay.ltd</li></div>
        <div class="indent"><li>Horario de Oficina: De lunes a sábado de 8:30 a 17:00</li></div>
        <div class="indent"><li>Página Web de la Empresa: Credipay SAC</li></div>
        <div>&nbsp;</div>
        <div>Por favor, lea y comprenda estos términos y condiciones antes de utilizar el servicio de Credipay SAC. Al utilizar nuestro servicio, usted acepta estar sujeto a estos términos.</div>
      </div>
    </div>
    <myFoot />
    <goTop />
  </div>
</template>

<script>
import myHead from "@/components/header";
import myFoot from "@/components/footer";
import goTop from "@/components/goTop";
export default {
  data() {
    return {
      stylehead: {
        color: "#272727",
        background: "#fff",
      },
      positive: "absolute",
    };
  },
  methods: {},
  components: {
    myHead,
    myFoot,
    goTop,
  },
};
</script>

<style lang="scss" scoped>
.home-page {
  position: relative;
  // padding-bottom: 300px;
  .time-section {
    position: relative;
    .img {
      display: block;
      max-width: 100%;
      border: none;
      outline: none;
    }
    .sub-title {
      width: 100%;
      position: absolute;
      top: 30%;
      left: 0%;
      font-size: 30px;
      line-height: 40px;
      color: #fff;
      padding-left: 80px;

      p {
        font-size: 80px;
        font-family: Baskerville-BoldItalic, Baskerville;
        font-weight: normal;
        color: #FFFFFF;
        line-height: 90px;
        letter-spacing: 1px;
      }
      div {
        font-size: 50px;
        font-family: Helvetica;
        color: #FFFFFF;
        line-height: 63px;
      }
      .info {
        width: 50%;
        font-size: 20px;
        font-family: ArialMT;
        color: #FFFFFF;
        line-height: 30px;
      }
      .btnbox {
        width: 250px;
        height: 50px;
        margin-top: 30px;
        position: relative;
        cursor: pointer;
        .btns {
          width: 100%;
          height: 100%;
          background: #FFCC9B;
          border-radius: 5px;
          position: absolute;
          left: 10px;
          top: 10px;
          // z-index: -1;
        }
        .btn {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #F4861D;
          border-radius: 5px;
          position: relative;
          z-index: 9;
          img {
            width: 30px;
            margin-right: 10px;
          }
          span {
            font-size: 20px;
            font-family: MuktaMahee-Bold, MuktaMahee;
            font-weight: bold;
            color: #FFFFFF;
            letter-spacing: 1px;
          }
        }
      }
    }
    .bottom-box {
      position: absolute;
      left: 50%;
      bottom: 50px;
      transform: translateX(-50%);
      img {
        width: 88px;
        height: 44px;
      }
    }
    
  }
  > .title {
    height: 100px;
    background: #860000;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    color: #fff;
  }
  .body {
    width: 100%;
    background: #fff;
    padding: 50px 0px;
    
    .content {
      width: 80%;
      margin: 0 auto;

    }
  }
}
* {
  font-size: 16px;
  line-height: 2;
  color: #333;
  margin: 0;
  padding: 0;
  list-style: disc;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
  line-height: 0;
  clear: both;
}
.wrapper {
  padding: 0 16px;
}
@media screen and (min-width: 450px) {
  .wrapper {
    width: 1050px;
    margin: 0 auto;
  }
}

h1 {
  text-align: center;
}

.title {
  font-size: 20px;
  color: #333;
  font-weight: bold;
  text-align: center;
}

.title1 {
  font-weight: bold;
  font-size: 18px;
}

dd {
  margin-inline-start: 0;
}

.indent {
  text-indent: 2em;
}

p {
  line-height: 24px;
  margin-block-start: 0;
  margin-block-end: 0;
  font-weight: 300;
  font-family: PingFang SC, -apple-system, BlinkMacSystemFont, Helvetica Neue,
    Microsoft YaHei, Source Han Sans SC, Noto Sans CJK SC, WenQuanYi Micro Hei,
    sans-serif;
}
::v-deep .el-collapse-item__header {
    font-size: 18px !important;
  }

</style>
