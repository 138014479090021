<template>
  <div class="home-page">
    <myHead :stylehead="stylehead"></myHead>
    <!-- <section class="time-section">
      <img class="img" width="100%" src="../assets/homebg.png" />
      <div class="sub-title">
        <p>QPréstamo</p>
        <h1>Préstamos llegan a tiempo</h1>
        <div class="info">QPréstamo utiliza inteligencia artificial y tecnología de control de riesgos de big data para brindar a los usuarios servicios de crédito financiero convenientes, rápidos y seguros</div>
        <span class="home-line"></span>
        <div class="btnbox">
          <div class="btn">
            <img src="../assets/logo2.png" alt="">
            <span>Descargar ahora</span>
          </div>
          <div class="btns"></div>
        </div>
      </div>
    </section> -->
    <div class="body">
      <div class="topbox">
        <div class="bgbox">
          <img src="../assets/aboutbg.png" alt="">
        </div>
      </div>
      <div class="content">
        <div class="title">
          <img src="../assets/title4.png" alt="">
        </div>
        <div class="box">
          <div class="left">
            <div class="tit">CREDIPAY</div>
            <div class="info">es una plataforma que proporciona préstamos en línea rápidos, accesibles y seguros. Regístrese en segundos, solicite un préstamo y reciba su efectivo en minutos. Vemos el mundo de otra manera. Nos impulsa una creencia fundamental en las personas y trabajamos juntos para demostrar su potencial. Tomamos riesgos que otros no tomarán, poniendo más poder en manos de nuestros clientes.</div>
          </div>
          <div class="right">
            <img src="../assets/item5.png" alt="">
          </div>
        </div>
      </div>
    </div>
    <myFoot />
    <goTop />
  </div>
</template>

<script>
import myHead from "@/components/header";
import myFoot from "@/components/footer";
import goTop from "@/components/goTop";

export default {
  data() {
    return {
      stylehead: {
        color: "#272727",
        background: "#fff",
      },
      positive: "absolute",
      money: 0
    };
  },
  created() {
    console.log(this.language);
  },
  methods: {
    changeLanguage(lang) {
      this.language = lang;
    },
  },
  components: {
    myHead,
    myFoot,
    goTop,
  },
};
</script>

<style lang="scss" scoped>
.home-page {
  position: relative;
  // padding-bottom: 300px;

  .time-section {
    width: 100%;
    position: relative;
    .img {
      display: block;
      max-width: 100%;
      border: none;
      outline: none;
    }
    .sub-title {
      // width: 100%;
      position: absolute;
      top: 30%;
      left: 0%;
      font-size: 30px;
      line-height: 40px;
      color: #fff;
      padding-left: 80px;

      p {
        font-size: 80px;
        font-family: Baskerville-BoldItalic, Baskerville;
        font-weight: normal;
        color: #FFFFFF;
        line-height: 90px;
        letter-spacing: 1px;
      }
      h1 {
        font-size: 50px;
        font-family: Helvetica;
        color: #FFFFFF;
        line-height: 63px;
      }
      .info {
        width: 50%;
        font-size: 20px;
        font-family: ArialMT;
        color: #FFFFFF;
        line-height: 30px;
      }
      .btnbox {
        width: 250px;
        height: 50px;
        margin-top: 30px;
        position: relative;
        cursor: pointer;
        .btns {
          width: 100%;
          height: 100%;
          background: #FFCC9B;
          border-radius: 5px;
          position: absolute;
          left: 10px;
          top: 10px;
          // z-index: -1;
        }
        .btn {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #F4861D;
          border-radius: 5px;
          position: relative;
          z-index: 9;
          img {
            width: 30px;
            margin-right: 10px;
          }
          span {
            font-size: 20px;
            font-family: MuktaMahee-Bold, MuktaMahee;
            font-weight: bold;
            color: #FFFFFF;
            letter-spacing: 1px;
          }
        }
      }
    }
  }
  .body {
    width: 100%;
    background: #fff;

    .topbox {
      
      .top {
        padding: 40px 0px;
        display: flex;

        .left {
          width: 50%;
          text-align: center;
          
          .txt {
            font-size: 20px;
            font-family: ArialMT;
            color: #860000;
            line-height: 25px;
            letter-spacing: 1px;
          }
          .money {
            font-size: 50px;
            font-family: Maler-Regular, Maler;
            font-weight: bold;
            color: #860000;
            line-height: 70px;
            span {
              font-size: 24px;
            }
          }
          .info {
            font-size: 18px;
            font-family: ArialMT;
            color: rgba(39,39,39,0.5000);
            line-height: 25px
          }
        }
        .right {
          width: 50%;
          display: flex;
          align-items: center;
          justify-content: center;

          .google {
            width: 300px;
            display: flex;
            align-items: center;
            border-radius: 8px;
            border: 2px solid #860000;

            img {
              width: 70px;
            }
            .box {
              flex: 1;
              padding: 10px 0;
              text-align: center;
              .info {
                font-size: 18px;
                font-family: ArialMT;
                color: rgba(39,39,39,0.5000);
                line-height: 25px;
              }
              .txt {
                font-size: 30px;
                font-family: Maler-Regular, Maler;
                font-weight: bold;
                color: #860000;
                line-height: 40px;
              }
            }
          }
        }
      }
      .bgbox {
        width: 100%;
        img {
          width: 100%;
        }
      }
    }

    .content {

      .title {
        height: 100px;
        background: #860000;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .box {
        padding: 40px 80px;
        background-color: #fff;
        display: flex;
        justify-content: space-between;
        .left {
          width: 60%;
          .tit {
            font-size: 40px;
            font-family: Maler-Regular, Maler;
            font-weight: bold;
            color: #860000;
          }
          .info {
            margin-top: 20px;
            font-size: 18px;
            font-family: ArialMT;
            color: #272727;
            line-height: 22px;
          }
        }
        .right {
          width: 30%;
          img {
            width: 70%;
          }
        }
      }
    }
  }
}
</style>
